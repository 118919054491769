<template>
<div>
  <h2>Bug Tracker</h2>
  <div class="container">
    <h3>Submit a bug</h3>
    <div class="content">
      <b-form @submit="onSubmit" @reset="onReset" v-if="show">
	<b-form-group
	  id="input-group-1"
	  description="We'll never share your email with anyone else."
	  >
	  <label for="input-1">Email address:</label>
	  <b-form-input
            id="input-1"
            v-model="form.email"
            type="email"
            required
            ></b-form-input>
	</b-form-group>
	
	<b-form-group id="input-group-2">
	  <label for="input-2">(Optional) Your SteamID:</label>
	  <b-form-input
            id="input-2"
            v-model="form.steamId"
            ></b-form-input>
	</b-form-group>
	
	<b-form-group id="input-group-3">
	  <label for="input-3">Platform:</label>
	  <b-form-select
            id="input-3"
            v-model="form.platform"
            :options="platforms"
            required
            ></b-form-select>
	</b-form-group>
	
	<b-form-group id="input-group-4">
	  <label for="input-4">Subject:</label>
	  <b-form-input
            id="input-4"
            v-model="form.subject"
            required
            ></b-form-input>	
	</b-form-group>

	<b-form-group id="input-group-5">
	  <label for="input-5">Game Mode:</label>
	  <b-form-input
            id="input-5"
            v-model="form.gameMode"
            required
            ></b-form-input>
	  </b-form-group>
	
	<b-form-group id="input-group-6">
	  <label for="input-6">Turn Number:</label>
	  <b-form-input
            id="input-6"
            v-model="form.turnNumber"
            required
            ></b-form-input>
	  </b-form-group>
	
	<b-form-group id="input-group-7" v-slot="{ ariaDescribedby }">
	  <b-form-checkbox-group
            id="checkboxes-7"
            :aria-describedby="ariaDescribedby"
            >
	    <p>Please describe your bug in one sentence below. Avoid using non-descriptive words like "it glitches" or "its broken"
	    </p>
	  </b-form-checkbox-group>
	</b-form-group>
	
	<b-form-group id="input-group-8">
	  <label for="input-8">Description:</label>
	  <b-form-textarea
	    id="input-8"
	    v-model="form.description"
	    rows="3"
	    max-rows="6"
	    required
	    ></b-form-textarea>
	</b-form-group>
	
	<b-form-group id="input-group-9">
	  <label for="input-9">Steps to reproduce:</label>
	  <b-form-textarea
	    id="input-9"
	    v-model="form.steps"
	    rows="3"
	    max-rows="6"
	    required
	    ></b-form-textarea>
	  <p>
	    Please list each action necessary to make the bug happen. These have to be reproducible.
	  </p>
	</b-form-group>
	
	<b-form-group id="input-group-7">
	  <label for="input-7">Expected result</label>
	  <b-form-textarea
	    id="input-7"
	    v-model="form.expectedRes"
	    rows="3"
	    max-rows="6"
	    required
	    ></b-form-textarea>
	  <p>
	    What should happen if the bug wasn't there?
	  </p>
	</b-form-group>
	
	<b-form-group id="input-group-8">
	  <label for="input-8">Actual result</label>
	  <b-form-textarea
	    id="input-8"
	    v-model="form.actualRes"
	    rows="3"
	    max-rows="6"
	    required
	    ></b-form-textarea>
	  <p>
	    What actually happens if you follow the steps.
	  </p>
	</b-form-group>
	
	<b-form-group id="input-group-9">
	  <label for="input-9">Build number</label>
	  <b-form-input
            id="input-9"
            v-model="form.currentBuild"
            required
            ></b-form-input>
	  <p>
	    What client build are you experiencing this bug on?
	  </p>
	</b-form-group>
	<br/>
	<b-button type="submit" variant="primary" style="margin-right: 5%;">Submit</b-button>
	<b-button type="reset" variant="danger">Reset</b-button>
      </b-form>
    </div>
  </div>
  <div class="footer">
    <div class="hl"/>
    <p>Farseers Domain © 2021</p>
  </div>
</div>
</template>
<script>
const axios = require('axios');

export default {
    name: 'BugTrack',
    props: {
    },
    data() {
	return {
	    form: {
		email: '',
		steamId: '',
		platform: null,
		subject: '',
		gameMode: '',
		turnNumber: '',
		description: '',
		steps: '',
		expectedRes: '',
		actualRes: '',
		currentBuild: '',
	    },
	    platforms: [{text: 'Select One', value: null }, 'Windows 11', 'Windows 10', 'OS X', 'Linux'],
	    show: true,
	    response: '',
	}
    },
    methods: {
	onSubmit(evt) {
	    evt.preventDefault();
	    console.log('Using API ROOT URL: ' + process.env.VUE_APP_API_ROOT_URL);
	    axios.post(`${process.env.VUE_APP_API_ROOT_URL}/api/bt`, {
		email: this.form.email,
		steamId: this.form.steamId,
		platform: this.form.platform,
		subject: this.form.subject,
		gameMode: this.form.gameMode,
		turnNumber: this.form.turnNumber,
		description: this.form.description,
		steps: this.form.steps,
		expectedRes: this.form.expectedRes,
		actualRes: this.form.actualRes,
		currentBuild: this.form.currentBuild,
	    }).then(response => {
		console.log(response);
		this.response = JSON.stringify(response, null, 2);
		this.form.email = '';
		this.form.steamId = '';
		this.form.platform = null;
		this.form.subject = '';
		this.form.gameMode = '';
		this.form.turnNumber = '';
		this.form.description = '';
		this.form.steps = '';
		this.form.expectedRes = '';
		this.form.actualRes = '';
		this.form.currentBuild = '';
		this.show = false;
		this.$nextTick(() => { this.show = true });
		alert("Success ! Thanks for reporting, you're awesome.");
	    }).catch(error => {
		console.log(`err: ${error}`);
		this.response = 'Error: ' + this.response.status;
	    });
	},
	onReset(evt) {
	    evt.preventDefault();
	    this.form.email = '';
	    this.form.steamId = '';
	    this.form.platform = null;
	    this.form.subject = '';
	    this.form.gameMode = '';
	    this.form.turnNumber = '';
	    this.form.description = '';
	    this.form.steps = '';
	    this.form.expectedRes = '';
	    this.form.actualRes = '';
	    this.form.currentBuild = '';
	    this.show = false;
	    this.$nextTick(() => {this.show = true});
	},
    },
}
</script>
<style scoped>
h3 {
    margin-bottom:30px;
}
.container {
    padding: 0;
    width: 90%;
    text-align: left;
}
.content {
    max-width: 650px;
}
label {
    font-weight: bold;
}
.hl {
    border-top: 1px solid grey;
}
.footer {
    margin-top: 5%;
}
</style>
